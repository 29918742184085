.accountCreateContainer {
    max-width: 1800px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Lato', sans-serif;
}

.accountCreateContainer .col-md-1, .accountCreateContainer .col-md-2, .accountCreateContainer .col-md-3, .accountCreateContainer .col-md-4,
.accountCreateContainer .col-md-5, .accountCreateContainer .col-md-6, .accountCreateContainer .col-md-7, .accountCreateContainer .col-md-8, .accountCreateContainer .col {
    padding-left: 5px;
    padding-right: 5px;
}

.accountCreateContainer .row {
    margin-right: -5px;
    margin-left: -5px;
}

.accountCreateContainer label {
    font-weight: bold;
}

.centerText {
    text-align: center;
}

.userContent {
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.greenText {
    color: green;
}

.redText {
    color: red;
}

.clearAllIcon {
    color: #5D7B9A;
}

.clearAllIcon:hover {
    color: #0056b3;
    cursor: pointer;
}

.addUserInput0, .addUserInput1, .addUserInput2, .addUserInput3, .addUserInput4,
.addUserInput5, .addUserInput6, .addUserInput7, .addUserInput8, .addUserInput9 {
    border: thin solid red;
}

.officeSelect0 .css-yk16xz-control, .officeSelect1 .css-yk16xz-control, .officeSelect2 .css-yk16xz-control, .officeSelect3 .css-yk16xz-control, .officeSelect4 .css-yk16xz-control,
.officeSelect5 .css-yk16xz-control, .officeSelect6 .css-yk16xz-control, .officeSelect7 .css-yk16xz-control, .officeSelect8 .css-yk16xz-control, .officeSelect9 .css-yk16xz-control {
    border: thin solid red;
}