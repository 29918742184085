.pagination {
    float: right;
}

.dataTables_filter {
    float: right;
}

.dropdownAction {
    text-align: center;
}

.modal-open {
    overflow-y: scroll
}

.extend-btn {
    padding: 1px 6px;
}

.padding-left {
    padding-left: 50px;
}

.padding-right {
    padding-right: 50px;
}

.teamsFilterItems {
    width: 80%;
}

.filter-row {
    display: flex;
    flex-direction: row;
}