.fieldLabels {
    margin-top: 8px;
    margin-bottom: 0px;
    font-weight: bold;
    font-size: 14px;
}
.fieldValue {
    margin-top: 8px;
    margin-bottom: 0px;
    font-size: 14px;
}

.accountDetailContainer {
    max-width: 1600px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Lato', sans-serif;
}

.accountDetailsButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.updateButton {
    margin-left: 10px;
}

.accountDetailsTitle {
    text-align: center;
    font-weight: 400 !important

}
.backButtonLow {
    width: 135px;
    height: 35px;
    margin: 33px;
    margin-top: 13px;
    float: right
}

   

.teamRequestDetailsCol {
    padding: 5px 30px;
}
.teamRequestDetailsColInner {
    text-align: right;
    padding: 5px 30px;
    padding-right: 25px;
    padding-left: 0px;
}
.teamRequestDetailsColInnerLeft {
    text-align: left;
    padding: 5px 30px;
    padding-left: 0px;
}
.teamRequestDetailHr {
    margin-top: 0px;
}
.backButton {
    width: 135px;
    height: 35px;
    margin-top: -20px;
}
.topMargin {
    margin-top: -22px;
}
