p {
    margin-bottom: 0;
}

span, .bold {
    font-weight: bold;
}

.bottom-margin {
    margin-bottom: 1rem;
}

.group-list {
    height: 200px;
    overflow-y: auto;
    border: 2px solid #f8f7f3;
    padding-left: 1rem;
}