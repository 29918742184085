.dropdownAction {
    text-align: center;
}

.modal-open {
    overflow-y: scroll
}

.manageDelegates {
    width: 95%;
    padding: 20px 10px 20px 50px;
}

.manageDelegates h5 {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    font-family: 'Lato', sans-serif;
}

.iconRow {
    display: flex;
    justify-content: space-between;
}

.addDelegate {
    text-align: center;
    color: #5D7B9A;
    font-style: italic;
    font-weight: bold;
    float: right;
    margin: 0 5px;
    padding-top: 15px;
}

.iconContainer {
    float: right;
    width: 35%;
}