.delegateContainer {
    max-width: 700px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Lato', sans-serif;
}

.delegateTitle {
    text-align: center;
}

.delegateTitle h4 {
    font-weight: bold;
}

.delegateButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.delegateHr {
    margin-top: 0px;
}

.addDelegateField {
    margin-bottom: 10px;
}