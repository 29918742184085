.home {
    width: 95%;
    padding: 20px 10px 20px 50px;
}

.officeFilter {
    border-top: 20px;
    max-width: 500px;
}

.proOfficeLabel {
    margin-right: 10px;
    margin-bottom: 0px;
    font-family: 'Lato', sans-serif;
}

.proOfficeDropdown {
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
}

.accountSearch {
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 15px;
    font-family: 'Lato', sans-serif;
}

.accountActions {
    display: flex;
    align-items: center;
}

.actionAndFilter {
    display: flex;
    justify-content: space-between;
}

.filterItems {
    width: 70%;
}

th {
    cursor: pointer;
}

a:hover {
    cursor: pointer;
}