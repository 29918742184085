.teamsContainer {
    width: 90%;
    padding: 20px 50px 20px 87px;
}

.introductoryText {
    font-size: .975em;
    font-family: "Lato",sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
}

.accountActions {
    display: flex;
    /*align-items: center;*/
    float: right;
    padding-left: 30px;
    padding-top: 20px;
}

.selectedText{
    margin-bottom: 0rem !important
}

.addIcon {
    text-align: center;
    color: white;
    margin: 0 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-right: 20px;
    background-color: dodgerblue;
    box-shadow: none;
    border: none;
    border-radius: 5px;
    height: 40px;
}
.addIconDisabled {
    text-align: center;
    color: white;
    margin: 0 5px;
    margin-top: 5px;
    margin-bottom: 10px;
    padding-right: 20px;
    background-color: dodgerblue;
    box-shadow: none;
    border: none;
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
    border-radius: 5px;
    height: 40px;
}

.buttonStyle {
    background-color: #F6871F;
    color: #FFFFFF;
    font-size: 16px;
    height: 30px;
    width: 250px;
    box-shadow: none;
    border: none;
    padding: 10px 20px;
}

.formContainer {
    font-family: 'Lato', sans-serif;
    max-width: 100%;
    margin-top: 20px;
}

.rightText {
    text-align: left;
}

.teamsLabel {
    font-size: 12px;
    font-weight: 600;
}

.required {
    color: #F6871F
}

.delete {
    margin-top: 35px;
}
.termsContainer {
    width: 95%;
    text-align: justify;
    justify-content: center;
    padding: 10px;
    padding-left: 40px;
    
}
.containerOuter {
    width: 60%;
    margin-left: 300px;
    padding-top: 10px;
    align-content: center;
    border: solid #d7d8da 1px;
    margin-top: 20px
}
.checkStyle{
    padding: 20px;
    margin-left:10px
}
.acceptButton{
    text-align: center;
    padding: 10px;
}
.actionButtons {
    float: right;
}
@media screen and (max-width: 700px){
    .containerOuter{
        margin-left: 0px;
        width: 100%
    }
}
.greenText {
    color: green;
}

.redText {
    color: red;
}
.warningText {
    color: red;
    font-size: 12px;
    font-weight: 400;
    font-family: "Lato",sans-serif;
}
.warningBorder{
    border: 1px solid red
}

.addUserInput0, .addUserInput1, .addUserInput2, .addUserInput3, .addUserInput4,
.addUserInput5, .addUserInput6, .addUserInput7, .addUserInput8, .addUserInput9 {
    border: thin solid red;
}
.userContent {
    text-align: left;
    padding-left: 0px;
    padding-right: 5px;
    font-weight: 600
}

.bottomError {
    font-size: large;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    align-items: center;
}

.bottom-button {
    margin-right: 5px;
}

.float-right {
    float: right;
}

.float-left {
    float: left;
}

.required-text {
    font-size: x-small;
}

.region-text {
    margin-right: 5px;
    padding-right: 5px;
}

.nowrap {
    white-space: nowrap;
}