.pendingLabel
 {
    width: 210px;
    margin: 0 10px 0 10px;
    text-align: right;
}

.expireLabel {
    width: 200px;
    margin: 0 0 10px 0;
    text-align: center;
}