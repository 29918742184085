.bold {
    font-weight: bold;
}

.member-list {
    height: 200px;
    overflow-y: auto;
    border: 2px solid #f8f7f3;
    padding-left: 1rem;
}

.member-list > table {
    width: 100%
}