.title-container {
    margin-top: 10px;
    padding: 20px 10px 20px 10px;
    text-align: center;
}

.main-container {
    margin-top: 20px;
    padding: 20px 10px 20px 10px;
    width: 75%;
    border: 1px solid gray;
}

.submit-button {
    padding-left: 10px;
    margin-left: 10px;
}

.button-group {
    padding-top: 50px;
    float: right;
}

.error-text {
    color: red;
    font-weight: bold;
}