.appBody {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.appFooter {
    flex-shrink: 0;
    background-color: #134c7d;
}

.appContent {
    flex: 1 0 auto;
}

/*Global*/
.errorMessage {
    text-align: center;
    color: red;
    font-weight: bold;
    font-style: italic;
}

/*Overwrite hidden classes*/
.custom-control-input:checked ~ .custom-control-label::before {
    border-color: #3C3D3E;
    background-color: #3C3D3E;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #3C3D3E;
    border-radius: 5px;
}

.list-delete-button {
    border: none;
    background-color: transparent;
    margin-left: 20px;
}

.eUeqdG {
    overflow: visible !important;
    white-space: break-spaces !important;
    text-overflow: unset !important;
}

.dFsTSm {
    overflow: visible !important;
    white-space: break-spaces !important;
    text-overflow: unset !important;
}

.rdt_Table {
    min-height: 300px;
}