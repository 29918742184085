.fieldLabels {
    margin-top: 8px;
    margin-bottom: 0px;
    font-weight: bold;
}


.accountDetailContainer {
    max-width: 800px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Lato', sans-serif;
}

.accountDetailsButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.updateButton {
    margin-left: 10px;
}

.accountDetailsTitle {
    text-align: center;
}

.accountDetailsTitle h4 {
    font-weight: bold;
}

.accountDetailsCol {
    padding: 5px 30px;
}

.accountDetailHr {
    margin-top: 0px;
}

.adminRadio:hover, .adminRadio input:hover, .adminRadio label:hover {
    cursor: pointer;
}