.teamsContainer {
    width: 90%;
    padding: 20px 50px 20px 87px;
}

.introductoryText {
    font-size: .975em;
    font-family: "Lato",sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
   
}
.introductoryTextHeader {
    font-family: "Lato",sans-serif;
    padding-left: 10px;
    font-weight: 700;
    font-size: 25px;
}
.reasons{
    padding-left: 30px;
}
hr {
    border-top: 3px solid black;
}
.formsContainer {
    font-family: 'Lato', sans-serif;
    max-width: 100%;
    margin-top: 50px;
}
.rightText {
    text-align: left;
}
.required {
    color: #F6871F;
}
.teamsLabel {
    font-size: 12px;
    font-weight: 600;
}
.centerText {
    text-align: right;
    font-size: 16px;
    top: 50%;
    font-weight: 600;
}
.emailContainer {
    width: 95%;
    text-align: justify;
    justify-content: center;
    padding: 10px;
    padding-left: 40px;
}

.emailOuterContainer {
    width: 60%;
    margin-left: 300px;
    padding-top: 10px;
    align-content: center;
    border: solid black 2px;
    margin-top: 20px
}
.warningHelpText {
    color: red;
    font-size: 12px;
    font-weight: 400;
    font-family: "Lato",sans-serif;
}
.emailButtons {
    float: right;
}
.warningBorder {
    border: 1px solid red;
}
.rowActions {
    margin-top: 35px;
}
.addRowIcon {
    text-align: center;
    color: #37a0f4;
    font-weight: bold;
    background-color: white;
    box-shadow: none;
    border: none;
}

.addRowIconDisabled {
    text-align: center;
    color: #37a0f4;
    font-weight: bold;
    background-color: white;
    box-shadow: none;
    border: none;
    opacity: 0.5;
}

