.confirm-container {
    margin-top: 10px;
    padding: 20px 10px 20px 10px;
    display: flex;
    align-items: center
}

.align-middle {
    margin-left: auto;
    margin-right: auto;
}