.home {
    width: 95%;
    padding: 20px 10px 20px 50px;
}

.menu {
    margin-top: 25px;
}

.menu-row > a > img {
    padding: 15px;
}

h1 {
    font-style: italic;
}

.home-icons:hover {
    filter: brightness(90%);
    filter: contrast(90%);
    filter: drop-shadow(0 0 0.75rem #00CC1A);
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-30{
    margin-bottom: 30px;
}

.home-icons {
    max-height: 252px;
    max-width: 201px;
    height: auto;
    width: 100%;
    filter: brightness(90%);
    filter: contrast(90%);
    filter: drop-shadow(5px 5px 8px black);
}

@media screen and (max-width: 768px) {
    .home-icons {
        margin-bottom: 20px;
    }
}