.AcceptButton {
    border-radius: 3px;
    color: #ffffff;
    display: inline-block;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    text-decoration: none;
    width: 150px;
    -webkit-text-size-adjust: none;
    mso-hide: all;
}

.DisableButton {
    border-radius: 3px;
    color: #ffffff;
    display: inline-block;
    font-family: sans-serif;
    font-size: 16px;
    line-height: 44px;
    text-align: center;
    text-decoration: none;
    width: 150px;
    -webkit-text-size-adjust: none;
    mso-hide: all;
}

.padding-left-20 {
    padding-left: 20px;
    margin-bottom: 10px;
}

.padding-top-20 {
    padding-top: 20px;
}

.div-wrapper {
    margin: 10px 30px;
}