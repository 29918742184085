div.filter {
    display: inline-flex;
    background-color: white;
    padding: 0 0 7px 0;
    padding-top: 3px;
    font-family: "Lato",sans-serif;
    font-size: 16px
}

input[type=checkbox] {
    height: 20px;
    width: 20px;
}

.statusOptions:hover {
    cursor: pointer;
}
