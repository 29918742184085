.companyContainer {
    max-width: 1000px;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Lato', sans-serif;
}

.companyTitle {
    text-align: center;
}

    .companyTitle h4 {
        font-weight: bold;
    }

.companyHr {
    margin-top: 0px;
}

.companyCol {
    padding: 5px 30px;
}

.companyButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.fieldLabels {
    margin-top: 8px;
    margin-bottom: 0px;
    font-weight: bold;
}

.deleteRadio:hover, .deleteRadio input:hover, .deleteRadio label:hover {
    cursor: pointer;
}

.companyIcon {
    display: inline-flex;
    align-items: center;
}

.companyAddIcon {
    color: #007bff;
    margin-right: 10px;
}

.companyDeleteIcon {
    color: #5D7B9A;
}

.companyAddIcon:hover {
    color: #0056b3;
    margin-right: 10px;
    cursor: pointer;
}

.companyDeleteIcon:hover {
    color: #0056b3;
    cursor: pointer;
}

.companyCol .col-md-10 {
    padding-right: 0px;
}
