.dropdownAction {
    text-align: center;
}

.modal-open {
    overflow-y: scroll
}

.resource-container {
    width: 98%;
    padding: 20px 10px 20px 50px;
}

.row-container {
    padding: 0 0 10px 0;
}

    .resource-container h5 {
        font-weight: bold;
        margin-top: 10px;
        margin-bottom: 15px;
        font-family: 'Lato', sans-serif;
    }

.icon-row {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}

.help-text {
    text-align: center;
    padding-bottom: 25px;
}

.help-steps {
    text-align: left;
    width: 1000px;
    margin: auto;
}

.need-help {
    text-align: center;
    float: right;
    margin: 0 5px;
    padding: 5px 10px;
    border: solid 1px;
}

.iconContainer {
    float: right;
}
