.header {
    background: linear-gradient(to right, #00acc4 0%, #5d7b9a 100%);
    padding: 0px;
    font-family: 'Lato', sans-serif;
    width: 100%;
}

.flexHeader {
    width: 100%;
}

.helpSection {
    width: 100%;
    background-color: #000000;
}

.helpLink, .helpLink:hover {
    color: #FFFFFF;
    margin: 5px 25px;
    display: inline-block;
}

.headerLogo, .headerLogo:hover {
    margin: 5px 25px;
    text-align: center;
    text-decoration: none;
}

.headerLogo h2 {
    color: #FFFFFF;
    margin-bottom: 0px;
}

.headerLogo h5 {
    color: #F6871F;
}

.navbarOptions {
    display: flex;
}

.navbarItems {
    flex-direction: row;
}

.navbarItems .navbarItem, .navbar-expand-lg .navbar-nav .nav-link {
    color: white;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 35px;
}

.navbarItems .navbarItem:hover, .navbar-expand-lg .navbar-nav .nav-link:hover {
    color: lightgray;
}

.dropdownHeader {
    position: relative;
    display: inline-block;
    float: right;
    background-color: lightgray;
}

.dropdownName {
    margin: 5px 10px;
}

.dropdownContent {
    display: none;
    position: absolute;
    background-color: lightgray;
    right: 0px;
    left: auto;
    white-space: nowrap;
}

.dropdownContent a {
    color: black;
    padding: 10px;
    text-decoration: none;
    display: block;
}

.dropdownHeader:hover {
    background-color: silver;
    cursor: pointer;
}

.dropdownContent a:hover {
    background-color: silver;
}

.dropdownHeader:hover .dropdownContent {
    display: block;
}

.questionIcon {
    font-size: 18px;
}

.alertCenter {
    text-align: center;
    font-weight: bold;
}

.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: 0.5;
}