@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@900&display=swap');

.error-container {
    font-family: 'Merriweather', serif;
    margin: 0;
    background-color: #9cc3d5;
    text-align: center;
    color: white;
    user-select: none;
    padding-top: 5%;
    padding-bottom: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #5ca3dd93;
}

.heading2 {
    font-size: 150px;
    margin: 0;
    text-shadow: 15px 5px 2px black;
}

.headingh3 {
    font-size: 40px;
    margin: 20px;
}

.paragraph {
    font-size: 18px;
    margin: 5px;
}

    .paragraph:last-of-type {
        margin-bottom: 35px;
    }

.link {
    text-decoration: none;
}
