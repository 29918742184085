
.footer {
    background: #3C3D3E;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAA25pVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOk9yaWdpbmFsRG9jdW1lbnRJRD0ieG1wLmRpZDo4NmNhNjM4Mi1jZThhLTQ5MDEtODFlMC1kMWI5MThjMjM3NzciIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RjI0QkQ5RjcxMEQyMTFFMzg1OThGQzdCRkM2QTVEOUEiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RjI0QkQ5RjYxMEQyMTFFMzg1OThGQzdCRkM2QTVEOUEiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDozRTdCNkEwREFCMDlFMzExOUNEOEI3M0E2NkIxRjZGQyIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo4NmNhNjM4Mi1jZThhLTQ5MDEtODFlMC1kMWI5MThjMjM3NzciLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz6o8tsQAAAAL0lEQVR42mL8//+/FAMSYGRkZGBBFwAqYmCEqYQJgNkgQWQBsCAQSyELgABAgAEAYUoVaNgFV+UAAAAASUVORK5CYII=");
    background-position: center;
    background-repeat: repeat;
    position: relative;
    margin: auto;
    overflow-x: hidden;
    color: white;
    padding: 20px;
}

.footer img {
    padding-bottom: 20px;
}

.footer .legal {
    font-size: .875em;
    font-family: "Lato",sans-serif;
    font-size: 14px;
    text-shadow: #444 0.1em 0.1em 0.1em;
}
.protiviti_logo{
    height: 60px;
    width: 140px
}
