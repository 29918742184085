.systemcontainer {
    margin-top: 30px;
    text-align: center;
    color: #5D7B9A;
    font-family: "Lato",sans-serif;
    font-weight: 600;  
}
.heading {
    font-weight: 700;
    margin-bottom: 20px;
    
}
.nav-pills {
    background-color: #f3f5f6;
}
.nav-pills:hover {
    background-color: rgba(200, 220, 227,0.35);
    color: darkblue
}
