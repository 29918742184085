.margin-left-50 {
    margin-left: 50px;
}

.margin-left-9 {
    margin-left: 9px;
}

.width-400 {
    width: 400px;
}

.edit-request-label {
    width: 210px;
    text-align: right;
}

.group {
    clear: both;
    padding-bottom: 10px;
}