.officeFilter {
    border-top: 20px;
    max-width: 500px;
}
.proOfficeDropdown {
    display: flex;
    align-items: center;
    font-family: 'Lato', sans-serif;
    z-index: 100;
}
.extendGrid{
    text-align: left;
    margin: auto;
}
div.extendGrid h2 {
    margin-bottom: 40px;
    margin-top: 20px;
    margin-left: 55px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    text-align: left;
}
.proOfficeLabel {
    margin-right: 10px;
    margin-bottom: 0px;
   
}
.dwnldBtn {
    text-align: center;
    margin-bottom: 5px;
    margin-top:20px;
} 

.btnText
{
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    color: #5D7B9A;
    font-weight: 300
}

.extendTitle {
    font-weight: bold;
    text-align: center;
    font-family: 'Lato', sans-serif;
}

.extendContainer {
    margin-top: 30px;
    max-width: 600px;
}

.extendButtons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 30px;
}

.extendHeading {
    padding-top: 30px;
    font-family: "Lato",sans-serif;
    font-weight: bold;
}

.extendHeading h4 {
    text-align: center;
    font-weight: bold;
}

.formContainer {
    font-family: 'Lato', sans-serif;
    max-width: 1800px
}

.formContainer label {
    font-weight: bold
}

.centerText {
    text-align: center;
}

.greenText {
    color: green;
}

.redText {
    color: red;
}

.warningText {
    color: red;
    font-size: 12px;
    font-family: "Lato",sans-serif;
}

.not-ready {
    pointer-events: "none";
}