.fieldLabels {
    margin-top: 8px;
    margin-bottom: 0px;
    margin-right: 10px;
    font-weight: bold;
}

.fieldValue {
    margin-top: 8px;
}

.font-16px {
    font-size: 16px !important;
}

.width200 {
    width: 200px;
}

.width50 {
    width: 50%;
}

.addAttachment {
    float: left;
    color: deepskyblue;
    margin-left: 30%;
}

.attachmentName {
    width: 350px;
    margin-left: 15%;
    margin-right: 10px;
}

.attachmentInput {
    width: 250px;
    float: left;
}

.updateButton {
    margin-left: 10px;
}

.requestDetailsTitle {
    text-align: left;
}

    .requestDetailsTitle h4 {
        font-weight: bold;
    }

.requestDetailsCol {
    padding: 5px 30px;
}

.requestDetailHr {
    margin-top: 0px;
}

.adminRadio:hover, .adminRadio input:hover, .adminRadio label:hover {
    cursor: pointer;
}

.textareaBox {
    float: left;
    width: 40%;
}

.selectBox {
    float: left;
    width: 20%;
}

.floatRight {
    float: right;
}

.floatLeft {
    float: left;
}

.center {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 25%;
    display: block;
}

.textAlignRight {
    text-align: right;
}

.unstyledButton {
    border: none;
    padding: 0 0 0 10px;
    background: none;

}

.exceed-limit {
    color: red;
    font-weight: bold;
}

.button-margin-left {
    margin-left: 10% !important;
}

.requestDetailContainer {
    max-width: 95%;
    margin-top: 30px;
    margin-bottom: 50px;
    font-family: 'Lato', sans-serif;
}

.requestDetailsButton {
    display: block;
    margin-left: 10%;
    margin-top: 20px;
    align-items: center;
    justify-content: flex-end;
}

.button-box {
    text-align: center;
    float: left;
    position: relative;
    min-height: 35px;
}

.min-height-134px {
    min-height: 134px;
}

.width-250 {
    width: 250px;
}

.width-150 {
    width: 150px;
}

.align-bottom {
    position: absolute;
    bottom: 0;
    right: 35%;
    margin-left: -30%;
}

.clear-fix {
    clear: both;
}

.warning {
    color: red;
}