.listcontainer {
    margin-top: 30px;
    font-family: "Lato",sans-serif; 
}

.dropdownAction {
    text-align: center;
}

.listcontainer h3 {
    font-weight: 700;
    text-align: center;
}

.downloadIcon {
    text-align: center;
    color: #5D7B9A;
    font-style: italic;
    font-weight: bold;
    margin: 0 5px;
    padding-right: 3px;
    display: flex;
    justify-content: flex-end;
}
.updateField{
    width: 60%;
    margin: 30px 30px 0px 230px;    
}
.emailInput {
    border-color: red;
}